// libs
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueBodyClass from 'vue-body-class';
import _ from 'lodash';

// components
import Cart from '@/views/Cart.vue';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import NotFound from '@/views/PageNotFound.vue';
import Redirect from '@/views/Redirect.vue';
import Onboarding from '@/views/Onboarding.vue';
import NewPassword from '@/views/NewPassword.vue';
import ResetPassword from '@/views/ForgotPassword.vue';
import Quiz from '@/components/partials/Quiz';

import onboardingRouter from './onboarding.js';
import { onboardingRoutes } from './onboarding.js';

Vue.use(VueRouter);

// services
import { AuthService } from '@/services/auth.service';
import { OnboardingService } from '@/services/onboarding.service';

const routes = [
  {
    path: '*',
    redirect: '/404',
    meta: {
      transition: 'overlay-up-down',
    },
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'Page not Found',
    },
  },
  {
    // id act as user credentials for example
    // /redirect/myidtoken, /redirect/myidnumber,
    path: '/redirect/',
    name: 'redirect',
    component: Redirect,
    meta: {
      transition: 'fade',
      title: 'Redirection in process...',
    },
  },
  {
    path: '/website/',
    beforeEnter(to, from, next) {
      location.href = `${process.env.VUE_APP_WEBSITE_URL}`;
      next(false);
    },
    meta: {
      title: 'Redirection in progress...',
    },
  },
  {
    path: '/website/contact',
    beforeEnter(to, from, next) {
      location.href = `${process.env.VUE_APP_WEBSITE_URL}/contact`;
      next(false);
    },
    meta: {
      title: 'Redirection in progress...',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      transition: 'fade-in-left',
      title: 'Login',
    },
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart,
    meta: {
      transition: 'fade',
      title: 'Cart',
      bodyClass: 'cart',
    },
  },
  {
    path: '/quiz',
    name: 'quiz',
    component: Quiz,
    meta: {
      transition: 'fade',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ResetPassword,
    meta: {
      transition: 'fade-in-left',
      title: 'Forgot Password',
    },
  },
  {
    path: '/new-password',
    name: 'new-password',
    component: NewPassword,
    meta: {
      transition: 'fade-in-left',
      title: 'New Pasword',
    },
  },
  {
    path: '/',
    name: 'onboarding',
    component: Onboarding,
    beforeEnter: onboardingRouter,
    meta: {
      bodyClass: 'getting-started',
      title: 'Onboarding',
    },
    beforeUpdate(to, from, next) {
      if (to == '/') {
        next(false);
      }
    },
    children: [
      {
        path: '/eligibility',
        component: () => import('@/components/onboarding/Eligibility.vue'),
        name: 'eligibility',
        meta: {
          transition: 'fade',
          title: 'Account Creation',
        },
      },
      {
        path: '/health-questions',
        component: () => import('@/components/onboarding/HealthQuestions.vue'),
        name: 'health-questions',
        meta: {
          transition: 'fade',
          title: 'Health Questions',
        },
      },
      {
        path: '/treatment-options',
        component: () => import('@/components/onboarding/TreatmentOptions.vue'),
        name: 'treatment-options',
        meta: {
          transition: 'fade',
          title: 'Treatment Options',
        },
      },
      {
        path: '/treatment-addons',
        component: () => import('@/components/onboarding/TreatmentAddons.vue'),
        name: 'treatment-addons',
        meta: {
          transition: 'fade',
          title: 'Treatment Add-ons',
        },
      },
      {
        path: '/identity-verification',
        name: 'identity-verification',
        component: () =>
          import('@/components/onboarding/IdentityVerification.vue'),
        meta: {
          transition: 'fade',
          title: 'Identity Verification',
        },
      },
      {
        path: '/checkout',
        name: 'checkout',
        component: () => import('@/components/onboarding/CheckOut.vue'),
        meta: {
          transition: 'fade',
          title: 'Check Out',
        },
      },
    ],
  },
  {
    path: '/home',
    name: 'index',
    component: Home,
    beforeEnter(to, from, next) {
      const head = document.head;
      const sas = document.getElementById('shareasale');
      if (sas) {
        head.removeChild(sas);
      }
      next();
    },
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/components/dashboard/Dashboard.vue'),
        meta: {
          transition: 'fade',
          title: 'Home',
        },
      },
      {
        // id act as user credentials
        path: '/treatment',
        name: 'treatment',
        component: () => import('@/components/dashboard/Treatment.vue'),
        meta: {
          transition: 'fade',
          title: 'Treatment',
        },
      },
      {
        path: '/messages',
        name: 'messages',
        component: () =>
          import('@/components/messages/MessageVersionSwitcher.vue'),
        meta: {
          transition: 'fade',
          title: 'Message',
          bodyClass: 'message-page',
        },
      },
      {
        path: '/message',
        redirect: '/messages/doctor',
      },
      {
        path: '/messages/doctor',
        name: 'message-doctor',
        component: () => import('@/components/dashboard/Message.vue'),
        meta: {
          transition: 'fade',
          title: 'Message',
          bodyClass: 'message-page',
        },
      },
      {
        path: '/messages/admin',
        name: 'message-admin',
        component: () => import('@/components/messages/AdminChatBase.vue'),
        meta: {
          transition: 'fade',
          title: 'Message',
          bodyClass: 'message-page',
        },
      },
      {
        path: '/support',
        name: 'support',
        component: () => import('@/components/dashboard/Support.vue'),
        meta: {
          transition: 'fade',
          title: 'Help Center',
          bodyClass: 'support',
        },
      },
      {
        path: '/subscription/:id',
        name: 'subscription',
        component: () => import('@/components/partials/Subscription.vue'),
        meta: {
          transition: 'fade',
          title: 'Subscription',
        },
      },
      {
        path: '/order/:id',
        name: 'order',
        component: () => import('@/components/partials/Order.vue'),
        meta: {
          transition: 'fade',
          title: 'My Order',
        },
      },
      {
        path: '/your-account',
        name: 'your-account',
        component: () => import('@/components/account/Account.vue'),
        meta: {
          transition: 'fade',
          title: 'Your Account',
        },
      },
      {
        path: '/account-settings',
        name: 'account-settings',
        component: () => import('@/components/account/Profile.vue'),
        meta: {
          transition: 'fade',
          title: 'Account Settings',
        },
        children: [
          {
            name: 'billing-details',
            path: '/account-settings/billing-details',
            meta: {
              title: 'Account Settings',
            },
          },
          {
            name: 'account-identity-verification',
            path: '/account-settings/identity-verification',
            meta: {
              title: 'Account Settings',
            },
          },
          {
            name: 'billing-address',
            path: '/account-settings/billing-address',
            meta: {
              title: 'Billing Address',
            },
          },
          {
            name: 'shipping-address',
            path: '/account-settings/shipping-address',
            meta: {
              title: 'Shipping Address',
            },
          },
          {
            name: 'update-email',
            path: '/account-settings/email',
            meta: {
              title: 'Update Email',
            },
          },
        ],
      },
      {
        path: '/medical-information',
        name: 'medical-information',
        component: () => import('@/components/account/MedicalInformation.vue'),
        meta: {
          transition: 'fade',
          title: 'Medical Information',
        },
      },
      {
        path: '/insurance-documents',
        name: 'insurance-documents',
        component: () => import('@/components/account/InsuranceDocuments.vue'),
        meta: {
          transition: 'fade',
          title: 'Insurance Documents',
        },
      },
      {
        path: '/contact-us',
        name: 'contact-us',
        component: () => import('@/components/partials/ContactUs.vue'),
        meta: {
          transition: 'fade',
          title: 'Contact Care Team',
        },
      },
      {
        path: '/cancel-subscription',
        name: 'cancel-subscription',
        component: () => import('@/components/partials/CancelSubscription.vue'),
        meta: {
          transition: 'fade',
          title: 'Cancel Subscription',
        },
      },
      {
        path: '/referral/',
        component: () => import('@/components/dashboard/Referrals'),
        meta: {
          transition: 'fade',
          title: 'Refer a Friend',
        },
        beforeEnter(to, from, next) {
          if (to.query.refcode) {
            Vue.$cookies.set('referralToken', to.query.refcode);
            if (localStorage.getItem('accessToken')) {
              next();
            } else {
              next({ name: 'eligibility' });
            }
          }
          next();
        },
      },
    ],
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

const vueBodyClass = new VueBodyClass(routes);
const DEAFULT_TITLE = 'Winona';

router.beforeEach(async (to, from, next) => {
  vueBodyClass.guard(to, next);

  // check if a datadog recording should start
  const allOnboardingRoutes = onboardingRoutes.concat('/eligibility');
  if (allOnboardingRoutes.concat('/eligibility').includes(to.path)) {
    if (
      process.env.NODE_ENV == 'production' &&
      typeof Vue.prototype.$ddRum != 'undefined'
    ) {
      Vue.prototype.$ddRum.datadogRum.startSessionReplayRecording();
    }
  }

  if (to.query.token) {
    localStorage.setItem('accessToken', `${to.query.token}`);
    localStorage.setItem('token', `Bearer ${to.query.token}`);
    const { data } = await AuthService.getTokenFromOTT();
    if (data) {
      localStorage.setItem('accessToken', `${data.token}`);
      localStorage.setItem('token', `Bearer ${data.token}`);
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('refreshToken', true);
    }
  }

  if (to.query.promocode || $cookies.get('promocode')) {
    if (!to.query.ec_id && !$cookies.get('promocodeExistingID')) {
      const promocode = to.query.promocode || $cookies.get('promocode');
      try {
        await OnboardingService.checkCoupon(promocode);
        // For those who are already logged in
        if (window.localStorage.getItem('accessToken')) {
          // * Temporarily opt for hardcoded values
          const blossom_promocodes = [
            'BFBLOSSOM40',
            'BFBLOSSOM40FIXED',
            'BLACKFRIDAY2022',
            'CYBERMONDAY2022',
            'BFBLOSSOM10',
          ];
          if (blossom_promocodes.includes(promocode)) {
            // Don't apply and remove
          } else {
            const { data } = await OnboardingService.getPatientOnboarding();
            if (
              data.onboardingstatus !== 'DONE' &&
              !data.usedreferralcode
            ) {
              await OnboardingService.applyCoupon({
                couponid: promocode,
              });
              $cookies.remove('promocode');
            }
          }
        }

        if (to.query.promocode && !window.localStorage.getItem('accessToken')) {
          $cookies.set('promocode', to.query.promocode);
        }
      } catch (err) {
        if (err == 'Error: Coupon does not exist.') {
          alert(
            'The coupon you entered does not exist, please check the code you entered.'
          );
        } else if (err == 'Error: Unauthorized request') {
          window.localStorage.removeItem('token');
          window.localStorage.removeItem('accessToken');
        } else if (err == 'Error: You\'ve already completed this step') {
          const newRouteQuery = _.omit(to.query, 'promocode');
          router.replace({ query: newRouteQuery });
          $cookies.remove('promocode');
        } else {
          alert(err);
        }
      }
    } else {
      if (!$cookies.get('promocode')) {
        $cookies.set('promocode', to.query.promocode);
      }
      if (!$cookies.get('promocodeExistingID')) {
        $cookies.set('promocodeExistingID', to.query.ec_id);
      }
    }
  }

  // Voluum click ID
  if (to.query.click_id) {
    const tbclidObj = { taboolaClickID: to.query.click_id };
    console.log('Click ID detected: ', tbclidObj);
    Vue.$cookies.set('taboolaClickID', tbclidObj);
  }

  document.title = DEAFULT_TITLE + ' | ' + to.meta.title;
  if (to.path == '/contact-us') {
    next();
  } else if (
    to.matched.some(record => record.meta.requiresAuth) &&
    !JSON.parse(localStorage.getItem('accessToken'))
  ) {
    next({ name: 'login' });
  } else {
    next();
  }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err && err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
};

export default router;
