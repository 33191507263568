const environment = process.env.VUE_APP_SERVER;

/**
 * Note: These constants are for non-sensitive information
 * such as URLs for redirection only. In the case that the env
 * variable reading fails, production variables are used
 * by default.
 */

import FILES from './files';

export { FILES };

export const FORMA_CODE = {
  FORMAT: 'NNNNN-NNNNN',
};

export const COOKIES = {
  SHOW_PROMO_FORM: {
    KEY: 'feature',
    VALUE: 'checkoutform',
    COOKIE_NAME: 'show_promo_form',
    COOKIE_DURATION: 60 * 60 * 24 * 30, // 1 month
  },
};

export const DATADOG = {
  APPLICATION_ID: 'ba42f9d0-269f-42f1-84ea-536aab48a694',
  CLIENT_TOKEN: 'pub383eda3ff63fc1b84588b8184c561fc6',
  SITE: 'us5.datadoghq.com',
  SERVICE: `${environment}:client-dashboard`,
};

export const TYPING_INDICATOR = {
  THROTTLE_TIME: 800,
};

export default Object.freeze(
  environment === 'staging'
    ? {
      COOKIES,
      DATADOG,
      FILES,
      KINDRA_URL: 'https://bywinona.com/kindra',
      WEBSITE_URL: 'https://staging.winona.workers.dev',
      OFFBOARDING_URL: 'https://staging-nuxt3.bywinona.com/other-offers',
    }
    : {
      COOKIES,
      DATADOG,
      FILES,
      KINDRA_URL: 'https://bywinona.com/kindra',
      WEBSITE_URL: 'https://bywinona.com',
      OFFBOARDING_URL: 'https://bywinona.com/other-offers'
    }
);
