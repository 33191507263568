import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class UpsellService extends BaseService {
  static async getProductList() {
    try {
      const response = await this.request({ auth: true }).get('upsell');
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async acceptUpsellProduct(upsellId, data) {
    try {
      if (typeof data.couponid == 'string' && data.couponid.length == 0) {
        delete data.couponid;
      } else if (typeof data.couponid == 'string' && data.couponid.length > 0) {
        data.coupon = data.couponid;
        delete data.couponid;
      }

      const response = await this.request({ auth: true }).put(
        `upsell/${upsellId}/accept`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async acceptMultipleUpsellProduct(data) {
    try {
      if (typeof data.couponid == 'string' && data.couponid.length == 0) {
        delete data.couponid;
      } else if (typeof data.couponid == 'string' && data.couponid.length > 0) {
        data.coupon = data.couponid;
        delete data.couponid;
      }

      const response = await this.request({ auth: true }).post(
        'upsell/accept',
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async denyUpsellProduct(upsellId) {
    try {
      const response = await this.request({ auth: true }).put(
        `upsell/${upsellId}/deny`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async addUpsellToPatient(product) {
    try {
      const response = await this.request({ auth: true }).post(
        `upsell/addtopatient/${product}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getDashboardUpsellList(showAll) {
    try {
      const response = await this.request({ auth: true }).get(
        `upsell/availableupsell${showAll ? '?show=all' : ''}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
