<template>
  <w-form
    class="mt-5"
    name="login"
    title="Hello, beautiful!"
    sub-title="Please log into your account."
  />
</template>

<script>
import { mapState } from "vuex";
import { OnboardingService } from "@/services/onboarding.service";
import wForm from "@/components/form/Form";
import LocalStorageService from "@/localStorage";
const localStorageService = LocalStorageService.getService();

export default {
  components: {
    wForm,
  },
  data() {
    return {
      isFetchingOnboardingStatus: false,
    };
  },
  async created() {
    // if (this.$route.query.flow && this.$route.query.flow == "b") {
    //   $cookies.set("onboardingflow", "flowB");
    // }

    // WIN-7758
    if (window.localStorage.getItem("token")) {
      try {
        this.isFetchingOnboardingStatus = true;
        let { data } = await OnboardingService.getPatientOnboarding();
        // if (data.onboardingstatus === "PENDING") {
        //   this.$router.push("/").catch(() => {});
        // }
        // else {
        //   this.$router.push("/").catch(() => {});
        // }
        this.isFetchingOnboardingStatus = false;
      } catch (err) {
        if (err.status == 401) {
          localStorageService.clearToken();
          //window.location.reload();
        }
      }
    }
  },
};
</script>
