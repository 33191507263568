import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';
import VueCookies from 'vue-cookies';

// * Exactly the same as the original onboarding service, but the endpoints lead to onboarding v2.

export class OnboardingService extends BaseService {
  static async collectEmail(body) {
    try {
      const response = await this.request({ auth: false }).post(
        '/patient/base',
        body
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error, null);
    }
  }

  static async onboardingEvent(eventname, data) {
    try {
      const config = { headers: {} };
      const campaign = await this.getCookieUTMS();
      if (campaign) {
        config.headers = campaign;
      }

      const ftcampaign = await this.getCookieFirstTouchUTMS();
      if (ftcampaign) {
        config.headers = { ...campaign, ...ftcampaign };
      }

      const fbp = VueCookies.get('_fbp') || null;
      const fbc = VueCookies.get('_fbc') || null;
      if (fbp) {
        config.headers.fbp = fbp;
      }
      if (fbc) {
        config.headers.fbc = fbc;
      }

      const body = data ? data : null;

      const response = await this.request({ auth: false, trackFB: true }).put(
        `/integromat/events/${eventname}`,
        body,
        config
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error, null);
    }
  }

  static async onboardingIdentify(body) {
    // Don't forget to add the user's ID
    // Supply eventname as OnboardingIdentify
    try {
      const response = await this.request({ auth: false, trackFB: true }).put(
        '/integromat/events/OnboardingIdentify',
        body
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error, null);
    }
  }

  static async rejectOnboardingEvent(body) {
    try {
      const response = await this.request({ auth: true, authKey: true }).post(
        '/patient/onboardingv2/reject',
        body
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error, null);
    }
  }

  static async getPatientOnboarding () {
    try {
      const response = await this.request({ auth: true, trackFB: true }).get(
        '/patient/onboardingv2'
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error, null);
    }
  }

  static async updateTreatmentForm(data, config = {}) {
    try {
      const response = await this.request({ auth: true, trackFB: true }).put(
        '/patient/onboardingv2/updatetreatmentform',
        data,
        config
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error, null, 'onboarding');
    }
  }

  static async getCookieUTMS() {
    let campaign = undefined;
    const winona_utms = VueCookies.get('winona_utms') || undefined;

    if (winona_utms != undefined && winona_utms.constructor.name === 'Object') {
      const utm_name = winona_utms.utm_name || undefined;
      const utm_source = winona_utms.utm_source || undefined;
      const utm_medium = winona_utms.utm_medium || undefined;
      const utm_campaign = winona_utms.utm_campaign || undefined;
      const utm_term = winona_utms.utm_term || undefined;
      const utm_content = winona_utms.utm_content || undefined;
      const utm_id = winona_utms.utm_id || undefined;
      if (
        utm_source != undefined ||
        utm_name != undefined ||
        utm_medium != undefined ||
        utm_campaign != undefined ||
        utm_term != undefined ||
        utm_content != undefined ||
        utm_id != undefined
      ) {
        campaign = {};
      }
      if (utm_name != undefined) {
        campaign['utm-name'] = utm_name;
      }
      if (utm_source != undefined) {
        campaign['utm-source'] = utm_source;
      }
      if (utm_medium != undefined) {
        campaign['utm-medium'] = utm_medium;
      }
      if (utm_campaign != undefined) {
        campaign['utm-campaign'] = utm_campaign;
      }
      if (utm_term != undefined) {
        campaign['utm-term'] = utm_term;
      }
      if (utm_content != undefined) {
        campaign['utm-content'] = utm_content;
      }
      if (utm_id != undefined) {
        campaign['utm-campaignId'] = utm_id;
      }

      // Converts objects to strings
      for (const utmkey in campaign) {
        if (typeof campaign[utmkey] == 'Object') {
          campaign[utmkey] = JSON.stringify(campaign[utmkey]);
          // if utm is a string, convert to ISO 8859-1 friendly format
        } else if (typeof campaign[utmkey] == 'string') {
          campaign[utmkey] = encodeURIComponent(campaign[utmkey]);
        }
      }
    }
    return campaign;
  }

  static async getCookieFirstTouchUTMS() {
    let campaign = undefined;
    const winona_firsttouch_utms =
      VueCookies.get('winona_firsttouch_utms') || undefined;

    if (
      winona_firsttouch_utms != undefined &&
      winona_firsttouch_utms.constructor.name === 'Object'
    ) {
      const utm_name = winona_firsttouch_utms.utm_name || undefined;
      const utm_source = winona_firsttouch_utms.utm_source || undefined;
      const utm_medium = winona_firsttouch_utms.utm_medium || undefined;
      const utm_campaign = winona_firsttouch_utms.utm_campaign || undefined;
      const utm_term = winona_firsttouch_utms.utm_term || undefined;
      const utm_content = winona_firsttouch_utms.utm_content || undefined;
      const utm_id = winona_firsttouch_utms.utm_id || undefined;
      if (
        utm_source != undefined ||
        utm_name != undefined ||
        utm_medium != undefined ||
        utm_campaign != undefined ||
        utm_term != undefined ||
        utm_content != undefined ||
        utm_id != undefined
      ) {
        campaign = {};
      }
      if (utm_name != undefined) {
        campaign['firsttouch-utm-name'] = utm_name;
      }
      if (utm_source != undefined) {
        campaign['firsttouch-utm-source'] = utm_source;
      }
      if (utm_medium != undefined) {
        campaign['firsttouch-utm-medium'] = utm_medium;
      }
      if (utm_campaign != undefined) {
        campaign['firsttouch-utm-campaign'] = utm_campaign;
      }
      if (utm_term != undefined) {
        campaign['firsttouch-utm-term'] = utm_term;
      }
      if (utm_content != undefined) {
        campaign['firsttouch-utm-content'] = utm_content;
      }
      if (utm_id != undefined) {
        campaign['firsttouch-utm-campaignId'] = utm_id;
      }
      // Converts objects to strings
      for (const utmkey in campaign) {
        if (typeof campaign[utmkey] == 'Object') {
          campaign[utmkey] = JSON.stringify(campaign[utmkey]);
          // if utm is a string, convert to ISO 8859-1 friendly format
        } else if (typeof campaign[utmkey] == 'string') {
          campaign[utmkey] = encodeURIComponent(campaign[utmkey]);
        }
      }
    }
    return campaign;
  }

  static async updatePatientOnboarding(step, data, config = {}) {
    try {
      const campaign = await this.getCookieUTMS();
      if (campaign) {
        config.headers = campaign;
      }

      const ftcampaign = await this.getCookieFirstTouchUTMS();
      if (ftcampaign) {
        config.headers = { ...campaign, ...ftcampaign };
      }

      if (typeof EF !== "undefined") {
        const transaction_id = EF.getAdvertiserTransactionId(1);
        if (EF && transaction_id) {
          if (!config.headers) {
            config.headers = { "everflow-transaction-id": transaction_id };
          } else {
            config.headers["everflow-transaction-id"] = transaction_id;
          }
          console.log(config.headers);
        }
      }

      let response;

      if (step == 7) {
        // Retry disabling
        response = await this.request({
          auth: true,
          trackFB: true,
          noRetry: true,
        }).put(`/patient/onboardingv2/step/${step}`, data, config);
      } else {
        response = await this.request({ auth: true, trackFB: true }).put(
          `/patient/onboardingv2/step/${step}`,
          data,
          config
        );
      }

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      if (step === 6) throw error;
      if (step === 7) throw new ErrorWrapper(error, null);
      throw new ErrorWrapper(error, null, 'onboarding');
    }
  }

  static async triggerOnboardingEvents(data, config = {}) {
    try {
      const campaign = await this.getCookieUTMS();
      if (campaign) {
        config.headers = campaign;
      }

      const ftcampaign = await this.getCookieFirstTouchUTMS();
      if (ftcampaign) {
        config.headers = { ...campaign, ...ftcampaign };
      }

      const response = await this.request({ auth: true, trackFB: true }).put(
        '/patient/onboardingv2/triggerevent',
        data,
        config
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error, null);
    }
  }

  static async getHealthQuestion(step) {
    try {
      const response = await this.request({ auth: true, trackFB: true }).get(
        `/patient/onboardingv2/step/${step}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error, null);
    }
  }

  static async getPreviousHealthQuestion(step) {
    try {
      const response = await this.request({ auth: true, trackFB: true }).get(
        `/patient/onboardingv2/step/${step}/previous`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error, null);
    }
  }

  static async getProductReveal() {
    try {
      const response = await this.request({ auth: true, trackFB: true }).get(
        '/patient/onboardingv2/productreveal'
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error, null);
    }
  }

  static async getProductRevealView(treatmentform) {
    try {
      const response = await this.request({ auth: true }).get(
        `/patient/onboardingv2/productreveal?treatmentform=${treatmentform}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async applyCoupon(data) {
    try {
      const response = await this.request({ auth: true }).put(
        '/patient/onboardingv2/applycoupon',
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error, null, 'onboarding');
    }
  }

  static async removeCoupon() {
    try {
      const response = await this.request({ auth: true }).delete(
        '/patient/onboardingv2/removecoupon'
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error, null, 'onboarding');
    }
  }

  static async checkCoupon(couponid) {
    try {
      const response = await this.request({ auth: false }).get(
        `/coupon/${couponid}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error, null);
    }
  }

  static async sendSASClickId(clickid) {
    try {
      const response = await this.request({ auth: true }).put(
        '/patient/onboardingv2/shareasale',
        {
          shareasaleclickid: clickid,
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error, null);
    }
  }
}
