import { AnalyticsService } from '../../services/analytics.service';

export default {
  namespaced: true,
  state: {
    isRoundedButtonTracked: false
  },
  mutations: {
    SET_ROUNDED_BUTTON_TRACKED(state, value) {
      state.isRoundedButtonTracked = value;
    }
  },
  getters: {},
  actions: {
    async trackSegmentCRO({ commit }, data) {
      await AnalyticsService.trackSegmentCRO(data);
    },
    trackRoundedButton({ commit }) {
      commit('SET_ROUNDED_BUTTON_TRACKED', true);
    }
  },
};