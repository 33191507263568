import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class AnalyticsService extends BaseService {
  static async trackSegmentCRO(data) {
    try {
      const response = await this.request({ auth: true }, 'v2').post(
        '/segment/cro/sse',
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}