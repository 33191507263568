import { OnboardingService } from '@/services/onboarding.service';

export default {
  namespaced: true,
  state: {
    isImageUploadProcessing: false,
    onboardingPatient: null,
  },
  mutations: {
    SET_IS_IMAGE_UPLOAD_PROCESSING(state, payload) {
      state.isImageUploadProcessing = payload;
    },
    SET_STATE(state, payload) {
      state[payload.state] = payload.value;
    },
  },
  actions: {
    async getPatientOnboarding ({ commit }) {
      const { data } = await OnboardingService.getPatientOnboarding();

      commit('SET_STATE', {
        state: 'onboardingPatient',
        value: data,
      });

      return data;
    },
  },
};
