import axios from "axios";
import axiosRetry from "axios-retry";
import { Http } from "./http.init";
import { ResponseWrapper, ErrorWrapper } from "./util";
import $store from "../store";
import $router from "../router";
import config from "../../config";
import VueCookies from "vue-cookies";

if (process.env.VUE_APP_SERVER == "local") {
  $cookies.config(604800, "/");
} else {
  $cookies.config(604800, "/", "bywinona.com", true);
}

let BEARER = "";
let API_URL = process.env.VUE_APP_API_URL;

axiosRetry(axios, {
  retries: 1,
  shouldResetTimeout: true,
  retryCondition: () => true,
  retryDelay: (retryCount) => {
    return retryCount * 3000;
  },
});
export class AuthService {
  static async login({ email, password }) {
    try {
      const response = await axios.post(`${API_URL}/signin`, {
        email,
        password,
        role: 'PATIENT',
      });
      _setAuthData({
        accessToken: response.data.body.idToken,
        exp: _parseTokenData(response.data.body.idToken).exp,
      });
      localStorage.setItem("isAuthenticated", true);
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getCookieUTMS() {
    let campaign = undefined;
    let winona_utms = VueCookies.get("winona_utms") || undefined;

    if (winona_utms != undefined && winona_utms.constructor.name === "Object") {
      let utm_name = winona_utms.utm_name || undefined;
      let utm_source = winona_utms.utm_source || undefined;
      let utm_medium = winona_utms.utm_medium || undefined;
      let utm_campaign = winona_utms.utm_campaign || undefined;
      let utm_term = winona_utms.utm_term || undefined;
      let utm_content = winona_utms.utm_content || undefined;
      let utm_id = winona_utms.utm_id || undefined;
      if (
        utm_source != undefined ||
        utm_name != undefined ||
        utm_medium != undefined ||
        utm_campaign != undefined ||
        utm_term != undefined ||
        utm_content != undefined ||
        utm_id != undefined
      ) {
        campaign = {};
      }
      if (utm_name != undefined) {
        campaign["utm-name"] = utm_name;
      }
      if (utm_source != undefined) {
        campaign["utm-source"] = utm_source;
      }
      if (utm_medium != undefined) {
        campaign["utm-medium"] = utm_medium;
      }
      if (utm_campaign != undefined) {
        campaign["utm-campaign"] = utm_campaign;
      }
      if (utm_term != undefined) {
        campaign["utm-term"] = utm_term;
      }
      if (utm_content != undefined) {
        campaign["utm-content"] = utm_content;
      }
      if (utm_id != undefined) {
        campaign["utm-id"] = utm_id;
      }
      // Converts objects to strings
      for (const utmkey in campaign) {
        if (typeof campaign[utmkey] == "Object") {
          campaign[utmkey] = JSON.stringify(campaign[utmkey]);
          // if utm is a string, convert to ISO 8859-1 friendly format
        } else if (typeof campaign[utmkey] == "string") {
          campaign[utmkey] = encodeURIComponent(campaign[utmkey]);
        }
      }
    }
    return campaign;
  }

  static async getCookieFirstTouchUTMS() {
    let campaign = undefined;
    let winona_firsttouch_utms =
      VueCookies.get("winona_firsttouch_utms") || undefined;

    if (
      winona_firsttouch_utms != undefined &&
      winona_firsttouch_utms.constructor.name === "Object"
    ) {
      let utm_name = winona_firsttouch_utms.utm_name || undefined;
      let utm_source = winona_firsttouch_utms.utm_source || undefined;
      let utm_medium = winona_firsttouch_utms.utm_medium || undefined;
      let utm_campaign = winona_firsttouch_utms.utm_campaign || undefined;
      let utm_term = winona_firsttouch_utms.utm_term || undefined;
      let utm_content = winona_firsttouch_utms.utm_content || undefined;
      let utm_id = winona_firsttouch_utms.utm_id || undefined;
      if (
        utm_source != undefined ||
        utm_name != undefined ||
        utm_medium != undefined ||
        utm_campaign != undefined ||
        utm_term != undefined ||
        utm_content != undefined ||
        utm_id != undefined
      ) {
        campaign = {};
      }
      if (utm_name != undefined) {
        campaign["firsttouch-utm-name"] = utm_name;
      }
      if (utm_source != undefined) {
        campaign["firsttouch-utm-source"] = utm_source;
      }
      if (utm_medium != undefined) {
        campaign["firsttouch-utm-medium"] = utm_medium;
      }
      if (utm_campaign != undefined) {
        campaign["firsttouch-utm-campaign"] = utm_campaign;
      }
      if (utm_term != undefined) {
        campaign["firsttouch-utm-term"] = utm_term;
      }
      if (utm_content != undefined) {
        campaign["firsttouch-utm-content"] = utm_content;
      }
      if (utm_id != undefined) {
        campaign["firsttouch-utm-id"] = utm_id;
      }
      // Converts objects to strings
      for (const utmkey in campaign) {
        if (typeof campaign[utmkey] == "Object") {
          campaign[utmkey] = JSON.stringify(campaign[utmkey]);
          // if utm is a string, convert to ISO 8859-1 friendly format
        } else if (typeof campaign[utmkey] == "string") {
          campaign[utmkey] = encodeURIComponent(campaign[utmkey]);
        }
      }
    }
    return campaign;
  }

  static async signup(data) {
    try {
      let campaign = await this.getCookieUTMS();
      const config = { headers: {} };
      if (campaign) {
        config.headers = campaign;
      }

      let ftcampaign = await this.getCookieFirstTouchUTMS();
      if (ftcampaign) {
        config.headers = { ...campaign, ...ftcampaign };
      }
      try {
        let fbp = VueCookies.get("_fbp") || null;
        let fbc = VueCookies.get("_fbc") || null;
        if (fbp) {
          config.headers.fbp = fbp;
        }
        if (fbc) {
          config.headers.fbc = fbc;
        }
      } catch (err) {
        console.log(err)
      }

      const response = await axios.post(`${API_URL}/signup`, data, config);

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async signupNonRx(data) {
    try {
      const response = await axios.post(`${API_URL}/signup/nonrx`, data);
      _setAuthData({
        accessToken: response.data.body.idToken,
        exp: _parseTokenData(response.data.body.idToken).exp,
      });
      localStorage.setItem("isAuthenticated", true);
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async logout() {
    try {
      const response = await new Http({ auth: true }).post("signout", {});
      _resetAuthData();
      //$router.push({ name: 'login' }).catch(() => { })
      //window.location.href = `${process.env.VUE_APP_WEBSITE_URL}/logout`;
      window.location.href = `${process.env.VUE_APP_WEBSITE_URL}`;
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async globeLogout() {
    try {
      const response = await new Http({ auth: true }).post("globalsignout", {});
      _resetAuthData();
      $router.push({ name: "login" }).catch(() => {});
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async refreshTokens(redirect = true) {
    try {
      const response = await new Http({ auth: true }).post("refreshtoken", {});

      _setAuthData({
        accessToken: response.data.body.idToken,
        exp: _parseTokenData(response.data.body.idToken).exp,
      });

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      if (redirect) {
        _resetAuthData();
        $router.push({ name: "login" }).catch(() => {});
      }
      throw new ErrorWrapper(error);
    }
  }

  static async getTokenFromOTT() {
    try {
      const response = await new Http({ auth: true }).post("gettoken", {});
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      if (redirect) {
        _resetAuthData();
        $router.push({ name: "login" }).catch(() => {});
      }
      throw new ErrorWrapper(error);
    }
  }

  static async getTokenFromOTT() {
    try {
      const response = await new Http({ auth: true }).post("gettoken", {});
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getOTTFromToken() {
    try {
      const response = await new Http({ auth: true }).post(
        "getonetimetoken",
        {}
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  /**
   ******************************
   * @METHODS
   ******************************
   */

  static isAccessTokenExpired() {
    const accessTokenExpDate = $store.state.auth.accessTokenExpDate - 10;
    const nowTime = Math.floor(new Date().getTime() / 1000);

    return accessTokenExpDate <= nowTime;
  }

  static hasRefreshToken() {
    return Boolean(localStorage.getItem("refreshToken"));
  }

  static setRefreshToken(status) {
    if (!["", "true"].includes(status)) {
      throw new Error(
        `setRefreshToken: invalid value ${status}; Expect one of ['', 'true']`
      );
    }

    localStorage.setItem("refreshToken", status);
  }

  static getBearer() {
    return localStorage.getItem("token");
  }

  static setBearer(accessToken) {
    BEARER = `Bearer ${accessToken}`;
    localStorage.setItem("token", BEARER);
    localStorage.setItem("accessToken", accessToken);
    $cookies.set("accessToken", accessToken);
  }
}

/**
 ******************************
 * @private_methods
 ******************************
 */

function _parseTokenData(accessToken) {
  let payload = "";
  let tokenData = {};

  try {
    payload = accessToken.split(".")[1];
    tokenData = JSON.parse(atob(payload));
  } catch (error) {
    throw new Error(error);
  }

  return tokenData;
}

function _resetAuthData() {
  $store.commit("user/SET_CURRENT_USER", {});
  $store.commit("auth/SET_ATOKEN_EXP_DATE", null);
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("token");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("isAuthenticated");
  localStorage.removeItem("winona:onboarding-step");
  $cookies.remove("accessToken");
  $cookies.remove("referralToken");
  $cookies.remove("promocode");
  $cookies.remove("promocodeExistingID");
}

function _setAuthData({ accessToken, exp } = {}) {
  AuthService.setRefreshToken("true");
  AuthService.setBearer(accessToken);
  $store.commit("auth/SET_ATOKEN_EXP_DATE", exp);
}
