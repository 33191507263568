<template>
  <!-- 
  for loader type 5 added 'dark-bg' props
  there are 5 type of loader just change the number of the props 'loader-type'
  for custom text you can use 'v-slot:text' to create a multiple message
  -->
  <b-container
    fluid
    :class="[
      'pageloader',
      'type-' + loaderType,
      darkBg ? 'is-dark' : '',
      lightBg ? 'is-light' : '',
      neutralBg ? 'is-neutral' : '',
    ]"
  >
    <b-row class="height-fluid">
      <b-col cols="2" md="1" v-if="loaderType != 6">
        <b-row
          align-v="stretch"
          align-h="center"
          style="height: 100%"
          class="pagenav"
        >
          <b-col cols="12" sm="3" md="12" align-self="start" class="logo">
            <img
              v-if="darkBg"
              src="@/assets/images/dark-icon-logo.svg"
              width="40"
            />
            <img v-else src="@/assets/images/icon-logo.svg" width="40" />
          </b-col>
          <b-col cols="12" sm="9" md="12" align-self="end">
            <span class="sitelink" v-if="sideText == ''">
              DOCTOR CONSULTATION
            </span>
            <span class="sitelink" v-else>
              {{ sideText }}
            </span>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        :cols="loaderType == 6 ? '12' : '10'"
        :md="loaderType == 6 ? '12' : '11'"
      >
        <template v-if="loaderType == 1">
          <b-row align-h="end">
            <b-col cols="12" md="10" offset-md="2">
              <div class="image-holder">
                <img
                  id="patternImage"
                  class="float-right"
                  :src="image"
                  alt="Lilac pattern"
                />
              </div>
              <h1 class="mt-4 heading">
                {{ quote }}
              </h1>
            </b-col>
          </b-row>
        </template>
        <template v-if="loaderType == 2">
          <b-row
            align-v="center"
            no-gutters
            class="text-holder"
            style="height: 100%"
          >
            <b-col cols="12" offset-md="2">
              <p class="text-uppercase sub-title">
                <b>starting menopause consultation</b>
              </p>
              <h1
                class="heading"
                style="line-height: 58px; letter-spacing: -0.02em"
              >
                <b-row>
                  <b-col cols="12" offset-sm="1"> On your way </b-col>
                  <b-col cols="12" offset-sm="2"> to a better life! </b-col>
                </b-row>
              </h1>
            </b-col>
          </b-row>
        </template>
        <template v-if="loaderType == 3">
          <b-row align-v="center" class="text-holder" style="height: 100%">
            <b-col cols="12">
              <h1
                class="heading"
                style="line-height: 62px; letter-spacing: -0.02em"
              >
                <b-row no-gutters>
                  <b-col cols="12"> Hormone </b-col>
                  <b-col cols="12" offset-md="1"> Replacement </b-col>
                  <b-col cols="12" offset-md="2"> Therapy~ </b-col>
                </b-row>
              </h1>
              <b-row class="mt-6">
                <b-col offset-md="4">
                  <p style="font-size: 24px; line-height: 30px">
                    HRT replaces the estrogen you make less of at peri - and
                    menopause. It is safe, effective, and FDA approved.
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
        <!-- <template v-if="loaderType == 4">
          <b-row align-v="center" class="text-holder" style="height: 100%">
            <b-col cols="12" md="8" offset-md="2">
              <p class="text-uppercase">30 days to try it</p>
              <h1 class="heading" style="line-height: 54px; letter-spacing: -0.02em;">
                Get your money back if you’re not loving what you see
              </h1>
            </b-col>
          </b-row>
        </template> -->
        <template v-if="loaderType == 5">
          <b-row align-v="center" class="text-holder" style="height: 100%">
            <b-col cols="12" md="6" offset-md="3">
              <slot name="text"
                ><p class="text-uppercase">{{ text }}</p></slot
              >
              <!-- <b-button @click="clickHandler" size="lg" variant="link" class="ml-n4"><span class="loaderButtonText">{{ buttonText }}</span><span class="next-arrow-white mb-n1"></span></b-button> -->
              <b-button class="slight-rounded" pill @click="clickHandler" size="lg" variant="info">{{
                buttonText
              }}</b-button>
            </b-col>
          </b-row>
        </template>
        <template v-if="loaderType == 6">
          <b-row align-v="center" class="text-holder" style="height: 100%">
            <LottieAnimation
              path="./animations/lf20_hlqeyzf2.json"
              :loop="true"
              :width="250"
              :height="250"
            />
          </b-row>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation";

export default {
  components: {
    LottieAnimation,
  },
  props: {
    loaderType: {
      type: [String, Number],
      default: 1,
    },
    userid: {
      type: String,
      default: "",
    },
    image: {
      type: String,
    },
    hideImage: {
      type: Boolean,
      default: false,
    },
    // this is for loader type 2 text
    text: {
      type: String,
      default: "Feel free to enjoy WINONA products",
    },
    // this is for loader type 1
    quote: {
      type: String,
      default: "Feel free to enjoy WINONA products",
    },
    buttonText: {
      type: String,
      default: "Continue",
    },
    darkBg: {
      type: Boolean,
    },
    lightBg: {
      type: Boolean,
    },
    neutralBg: {
      type: Boolean,
    },
    sideText: {
      type: String,
      default: "",
    },
    messageType: {
      type: String,
      default: "",
    },
  },
  methods: {
    async clickHandler(event) {
      this.$emit("click", event);
      if (
        this.loaderType.toString() === "5" &&
        this.messageType === "doctormatching"
      ) {
        window.analytics.track(
          "Onboarding Screen Viewed",
          {
            customerId: this.userid,
            screen: "3.2-A",
          },
          {
            referrer: await this.$store.dispatch("getEverflowDetails"),
            context: await this.$store.dispatch("geDeviceDetails"),
          }
        );
      }
    },
  },

  async mounted() {
    if (
      this.loaderType.toString() === "5" &&
      this.messageType === "doctormatching"
    ) {
      window.analytics.track(
        "Onboarding Screen Viewed",
        {
          customerId: this.userid,
          screen: "3.2-A",
          ...(await this.$store.dispatch("getDeviceDetailsForContext")),
          ...(await this.$store.dispatch("getVoluumDetails")),
        },
        {
          referrer: await this.$store.dispatch("getEverflowDetails"),
          context: await this.$store.dispatch("geDeviceDetails"),
        }
      );
    }
  },

  async mounted() {
    if (
      this.loaderType.toString() === "5" &&
      this.messageType === "doctormatching"
    ) {
      window.analytics.track(
        "Onboarding Screen Viewed",
        {
          customerId: this.userid,
          screen: "3.1-A",
          ...(await this.$store.dispatch("getDeviceDetailsForContext")),
          ...(await this.$store.dispatch("getVoluumDetails")),
        },
        {
          referrer: await this.$store.dispatch("getEverflowDetails"),
          context: await this.$store.dispatch("geDeviceDetails"),
        }
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.loaderButtonText {
  font-weight: 400;
  padding-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: fit-content;
}

.pageloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: #edffe1;
  color: $primary;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;

  &.is-dark {
    background-color: $primary;
    color: $secondary;

    .btn-link {
      color: #a783ff, 100%;
      text-transform: Capitalize;
      font-size: 1.25rem;
    }
  }

  &.is-light {
    background-color: #fff;
  }

  &.is-neutral {
    background-color: #ede6ff;
  }

  .pagenav {
    height: 100%;
  }

  &.type-1 {
    .heading {
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -0.01em;

      @include media-breakpoint-up(xxl) {
        font-size: 28px * $scale-font;
        line-height: 36px * $scale-font;
      }
    }
  }

  &.type-2 {
    .heading {
      font-size: 54px;

      @include media-breakpoint-up(xxl) {
        font-size: 54px * $scale-font;
      }
    }

    p {
      font-size: 18px;
      letter-spacing: 0.14em;

      @include media-breakpoint-up(xxl) {
        font-size: 18px * $scale-font;
      }
    }
  }

  &.type-3 {
    .heading {
      font-size: 54px;

      @include media-breakpoint-up(xxl) {
        font-size: 54px * $scale-font;
      }
    }
  }

  &.type-4 {
    .heading {
      font-size: 48px;

      @include media-breakpoint-up(xxl) {
        font-size: 48px * $scale-font;
      }
    }

    p {
      font-size: 20px;
      letter-spacing: 0.14em;

      @include media-breakpoint-up(xxl) {
        font-size: 20px * $scale-font;
      }
    }
  }

  &.type-5 {
    p {
      font-size: 24px;
      opacity: 0.8;

      @include media-breakpoint-up(xxl) {
        font-size: 24px * $scale-font;
      }

      &.loader-highlight {
        opacity: 1;
      }
    }
  }

  .heading {
    color: inherit;
    line-height: 2rem;

    @include media-breakpoint-up(xxl) {
      font-size: 2rem * $scale-font;
    }
  }

  .sitelink {
    font-size: 16px;
    writing-mode: vertical-rl;
    transform: scale(-1, -1);
    letter-spacing: 0.18em;

    @include media-breakpoint-up(xxl) {
      font-size: 16px * $scale-font;
    }
  }

  .height-fluid {
    height: 100vh;
    padding: 1.5rem;
    margin: 0;
  }

  .image-holder {
    width: 100%;
    height: 60vh;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (max-width: 768px) {
  .pageloader {
    &.type-1 {
      .heading {
        font-size: 1.5rem !important;
      }
    }

    .pagenav {
      height: initial;
    }

    .image-holder {
      height: 35vh;
    }

    .text-holder {
      p {
        font-size: 18px;
        // letter-spacing: .5px;
      }
    }

    .heading {
      font-size: 2rem !important;
      line-height: initial !important;
    }

    .logo {
      margin-bottom: 1.5rem;

      img {
        width: 30px;
      }
    }
  }
}

.getting-started {
  .height-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
